:root {
    --navy-blue: #00113A;
    --mid-blue: #0042E4;
    --nelo-green: #8eb457;
    --nelo-light: #a6d166 ;
    --gray: #7E858f;
    --platinum: #CED5DF;
    --silver: #F1F3F6;
    --white: #FFFFFF;
}

.bg-navy-blue {
    background-color: var(--navy-blue) !important;
}

.text-navy-blue {
    color: var(--navy-blue) !important;
}

.bg-mid-blue {
    background-color: var(--mid-blue) !important;
}

.text-mid-blue {
    color: var(--mid-blue) !important;
}

.bg-gray {
    background-color: var(--gray) !important;
}

.text-gray {
    color: var(--gray) !important;
}

.bg-platinum {
    background-color: var(--platinum) !important;
}

.text-platinum {
    color: var(--platinum) !important;
}

.bg-silver {
    background-color: var(--silver) !important;
}

.text-silver {
    color: var(--silver) !important;
}

.bg-nelo-green {
    background-color: var(--nelo-green) !important;
}

.text-nelo-green  {
    color: var(--nelo-green) !important;
}

.bg-nelo-light {
    background-color: var(--nelo-light) !important;
}

.text-nelo-light  {
    color: var(--nelo-light) !important;
}



