.bg-blue-midnight {
  background-color: #061532 !important;
}

.text-blue-midnight {
  color: #061532 !important;
}

.bg-blue-navy {
  background-color: #0e2757 !important;
}

.bg-blue-sea {
  background-color: #005a7f !important;
}

.bg-blue-pacific {
  background-color: #00a9b7 !important;
}


.bg-black {
  background-color: #000000 !important;
}

.bg-gray-charcoal {
  background-color: #061532 !important;
}

.bg-gray-pastel {
  background-color: #cdcdcb !important;
}

.bg-lime-lima {
  background-color: #d1fa94 !important;
}

.bg-nelo-light {
  background-color: #a6d166 !important;
}
.bg-nelo-dark {
  background-color: #8eb457 !important;

}

.bg-dark-green {
  background-color: #222b13 !important;
}

.hero-1 {
  background-image: url('/src/assets/wave_form.svg');
  background-repeat: no-repeat;
  background-size: 55vw;
  background-position: center right;
}
.bg-contain-50 {
  background-size: 55vw;
}

.bg-contain-90 {
  background-size: 92vw;
}

.bg-contain-60 {
  background-size: 60vw;
}

.login-hero {
  background-image: url('/src/assets/login_hero.svg');
  background-repeat: no-repeat;
  background-size: 20vw;
  background-position: 80% 40vh;
}

/* .text-nelo-light, .Mui-selected {
  color: #a0cb60 !important;
} */